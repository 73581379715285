import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";
import { useParams } from 'react-router-dom';
import supabase from "helpers/SupabaseClient";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';


const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Highlight = tw.span`mt-2 px-2 py-1 rounded-full`;

export default ({ 
  navLinks = [],
}) => {
  const { url } = useParams();
  const [article, setArticle] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data, error } = await supabase
          .from('inbox')
          .select('*')
          .eq('id', url)
        console.log(data);
        setArticle(data);
        setIsChecked(data.length > 0 ? data[0].state : false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [isChecked]);

  var id, sender, duty, scjId, region, type, contact, state, date, content;
  const formattedMessage = article.length > 0 ? (() => {
      id = article[0].id;
      sender = article[0].name;
      duty = article[0].duty;
      scjId = article[0].scjid;
      region = article[0].region;
      type = article[0].type;
      contact = article[0].contact;
      state = article[0].state;
      content = article[0].comment;
      return `Heavenly Inbox`;
  })() : '';

  const setType = (type) => {
    if (type === "Suggestion"){
      return <Highlight tw='text-green-700 bg-green-100'>{type}</Highlight>
    }
    if (type === "Complaint"){
      return <Highlight tw='text-yellow-700 bg-yellow-100'>{type}</Highlight>
    }
    if (type === "Illegal Activity"){
      return <Highlight tw='text-red-700 bg-red-100'>{type}</Highlight>
    }
    if (type === false){
      return <Highlight tw='text-pink-700 bg-pink-100'>{"Unread"}</Highlight>
    } else {
      return <Highlight tw='text-green-700 bg-green-100'>{"Read"}</Highlight>
    }
  }

  const handleStatusChange = async (id, newStatus) => {
  const { error } = await supabase
    .from("inbox")
    .update({ 'state': newStatus })
    .eq("id", id);

    setIsChecked(newStatus);
  if (error) {
    console.error("Error updating status:", error);
  } else {
    console.log(`Notification ${id} marked as ${newStatus ? "Read" : "Unread"}`);
  }
};

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{formattedMessage}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            <h2>Message Information:</h2>
            <h4>| Name   : {sender}</h4>
            <h4>| Duty   : {duty}</h4>
            <h4>| SCJ ID : {scjId}</h4>
            <h4>| Region : {region}</h4>
            <h4>| Contact: {contact}</h4>
            <h4>| Type   : {setType(type)}</h4>
            <h4>| State  : {setType(state)}</h4>
            <h2>Message:</h2>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
            <br/><br/>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleStatusChange(id, !isChecked)}
              id={`checkbox-${id}`}
            />
              <label htmlFor={`checkbox-${id}`}>Mark as {isChecked ? "Unread" : "Read"}</label>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
