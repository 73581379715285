import supabase from "helpers/SupabaseClient";

export function getKey(args) {
  var args_array = Array.prototype.slice.call(args);
  return args_array.reduce((acc, val) => `${acc}-${val}`, "");
}

export const checkCache = (args) => {
  var argumentKey = getKey(args);

  var item = JSON.parse(localStorage.getItem(argumentKey));

  // Expired
  if (item != null) {
    if (new Date(item.expiry) < new Date()) {
      return null;
    }

    console.log(
      `Retrieved query with key ${argumentKey} from cache. Expires @ ${item.expiry}`
    );
    return item.data;
  } else {
    return null;
  }
};

export function setItem(args, data, expiresAt = 60) {
  var argumentKey = getKey(args);

  var expiry = new Date();
  expiry.setMinutes(expiry.getMinutes() + expiresAt);

  localStorage.setItem(argumentKey, JSON.stringify({ data, expiry }));
}

export const getPaginatedData = async (table, pageNumber, offset, order_by, asc = false) => {
    try {
        const { data, error } = await supabase
            .from(table)
            .select("*")
            .range((pageNumber - 1) * offset, pageNumber * offset - 1)
            .order(order_by, { ascending: asc });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    throw error;
  }
};

export async function getTableRowCount(table) {
  var cacheCheck = checkCache(arguments);

  if (!!cacheCheck) return cacheCheck;
  else {
    try {
      const { data, error, count } = await supabase
        .from(table)
        .select("*", { count: "exact", head: true });
      if (count != null) {
        setItem(arguments, count);
        return count;
      } else {
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }
}

export const searchByColumn = async (table, column, query, pageNumber, offset, order_by, asc = false) => {
  try {
    const searchTerms = query.trim().split(/\s+/); // Split on whitespace
    const formattedQuery = searchTerms.map((term) => `${term}:*`).join(" | ");
    const { data, error } = await supabase
      .from(table)
      .select("*")
      .filter(column, "fts", formattedQuery)
      .range((pageNumber - 1) * offset, pageNumber * offset - 1)
      .order(order_by, { ascending: asc });


    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error performing search by column:", error);
    throw error;
  }
};


export const countSearchByColumn = async (table, column, query) => {
  try {
    const searchTerms = query.trim().split(/\s+/); // Split on whitespace
    const formattedQuery = searchTerms.map((term) => `${term}:*`).join(" | ");
    const { data, error, count } = await supabase
      .from(table)
      .select("*", { count: "exact"})
      .filter(column, "fts", formattedQuery)



    if (error) throw error;
    return count;
  } catch (error) {
    console.error("Error performing search by column:", error);
    throw error;
  }
};


export const exportByColumn = async (table, column, query, order_by) => {
  try {
    const searchTerms = query.trim().split(/\s+/); // Split on whitespace
    const formattedQuery = searchTerms.map((term) => `${term}:*`).join(" | ");
    const { data, error } = await supabase
      .from(table)
      .select("*")
      .filter(column, "fts", formattedQuery)
      .order(order_by, { ascending: false })
      .csv();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error performing search by column:", error);
    throw error;
  }
};

export const searchByDate = async (table, column, start_date, end_date, pageNumber, offset, order_by, asc = false) => {
  try {

    var startDate = new Date(start_date)
    startDate.setHours(0,0,0)

    var endDate = new Date(end_date)
    endDate.setHours(11,59,59,999)
    

    const { data, error } = await supabase
      .from(table)
      .select("*")
      .gte(column, startDate.toISOString())
      .lte(column, endDate.toISOString())
      .range((pageNumber - 1) * offset, pageNumber * offset - 1)
      .order(order_by, { ascending: asc });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error performing search by column:", error);
    throw error;
  }
};

export const exportDateLogs = async (table, column, dateFrom, dateTo, order_by, limit, offset) => {
  try {

    var startDate = new Date(dateFrom)
    startDate.setHours(0,0,0)

    console.log(startDate.toString())

    var endDate = new Date(dateTo)
    endDate.setHours(11,59,59,999)

    console.log(startDate.toString())

    const { data, error } = await supabase
      .from(table)
      .select("*")
      .gte(column, startDate.toISOString())
      .lte(column, endDate.toISOString())
      .range(offset, offset + limit)
      .order(order_by, { ascending: false })
      .csv();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error performing search by column:", error);
    throw error;
  }
};

export const countDateLogs = async (table, column, start_date, end_date) => {
  try {

    var startDate = new Date(start_date)
    startDate.setHours(0,0,0)

    console.log(startDate)

    var endDate = new Date(end_date)
    endDate.setHours(11,59,59,999)

    console.log(endDate)

    const { _data, error, count }= await supabase
      .from(table)
      .select("*", { count: "exact", head: true })
      .gte(column, startDate.toISOString())
      .lte(column, endDate.toISOString())

    if (error) throw error;
    return count;
  } catch (error) {
    console.error("Error performing search by column:", error);
    throw error;
  }
}

export async function getAllEntries(table, order_by) {
  var cacheCheck = checkCache(arguments);

  if (!!cacheCheck) return cacheCheck;
  else {
    try {
      const { data, error } = await supabase
        .from(table)
        .select("*")
        .order(order_by, { ascending: false })
        .limit(100); // Limiting the results to 100 entries

      if (error) throw error;
      setItem(arguments, data, 60);
      return data;
    } catch (error) {
      console.error("Error fetching all entries:", error);
      throw error;
    }
  }
}

export async function hasDateColumn(tableName) {
  var cacheCheck = checkCache(arguments);

  if (!!cacheCheck) return cacheCheck;
  else {
    try {
      const { data, error } = await supabase
        .from(tableName)
        .select("date")
        .limit(1); // Only need to check the first row to see if 'date' exists

      if (error) throw error;
      setItem(arguments, data, 60);
      return data.length > 0 && data[0].date !== undefined; // Check if 'date' column exists
    } catch (error) {
      console.error("Error checking date column:", error);
      return false;
    }
  }
}

export const insertIntoTable = async (table, data) => {
  const { error } = await supabase.from(table).insert(data);

  return error;
};
