import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import logo from "images/lra_pink.svg"; 
import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
import { ReactComponent as FinanceIcon } from "feather-icons/dist/icons/bar-chart-2.svg";
import { ReactComponent as InboxIcon } from "feather-icons/dist/icons/inbox.svg";
import { Home, useAuth } from "helpers/Auth";

//TODO Add authentication to this page
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 w-full p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-2 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex flex-col mt-4`;

const SubmitButton = styled.button`
  ${tw`sm:w-full mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ 
  logoLinkUrl = "#",
}) => {
  const { user } = useAuth();
  const [stats, setStats] = useState([]);

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading><HighlightedText>{ "Admin Board" }</HighlightedText></Heading>
              <FormContainer tw="text-center">
                <SubmitButton onClick={Home}>
                    <HomeIcon className="icon" />
                    <span className="text">{"Home"}</span>
                  </SubmitButton>
                <SubmitButton onClick={() => window.location.href = "/logs"}>
                  <FinanceIcon className="icon" />
                  <span className="text">{"Logs Dashboard"}</span>
                </SubmitButton>
                <SubmitButton onClick={() => window.location.href = "/heavenly-inbox-messages"}>
                  <InboxIcon className="icon" />
                  <span className="text">{"Heavenly Inbox Dashboard"}</span>
                </SubmitButton>
              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
