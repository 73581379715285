import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import logo from "images/lra_pink.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as MailIcon } from "feather-icons/dist/icons/send.svg";
import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import { PrimaryButton } from "../components/misc/Buttons.js";
import supabase from "helpers/SupabaseClient"; // Assuming you're using supabase for database submission
import { Home, getUserInfo, useAuth } from "helpers/Auth";
import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-1/2 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-lg`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Text = styled.div`
  ${tw`text-sm mt-6 mb-6 text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const Label = tw.label`text-sm text-gray-900 text-center`;
const ValueLabel = tw.label`text-lg text-black font-bold text-center`;

export default ({
  logoLinkUrl = "#",
  headingText = "Heavenly Inbox",
  submitButtonText = "Submit",
  SubmitButtonIcon = MailIcon,
}) => {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [duty, setDuty] = useState("");
  const [scjid, setScjIdNumber] = useState("");
  const [region, setRegion] = useState("");
  const [comment, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [userData, setUserdata] = useState([]);
  const { user } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const pullData = async () => {
    const userdata = await getUserInfo(user.email);
    setUserdata(userdata);
    setName(userdata.name);
    setScjIdNumber(userdata.registration_number);
    setRegion(userdata.region);
  }
  
  useEffect(() => {
    pullData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation can be added here (e.g., check if fields are filled)
    if (!type || !name || !scjid || !region || !duty || !comment || !contact) {
      alert("Please fill in all fields.");
      return;
    }

    try {
      // Submit data to Supabase
      const { data, error } = await supabase
        .from("inbox") // Make sure to change this to your Supabase table
        .insert([
          {
            type,
            name,
            scjid,
            region,
            duty,
            comment,
            contact,
          },
        ]);

      if (error) {
        console.error("Error submitting form:", error);
      } else {
        console.log("Form submitted successfully:", data);
        // Reset form fields
        setName(userData.name);
        setScjIdNumber(userData.registration_number);
        setRegion(userData.region);
        setDuty("");
        setType("");
        setMessage("");
        setContact("");
        setModalIsOpen(true); // Show modal on success
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form onSubmit={handleSubmit}>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select inbox submission type
                    </option>
                    <option value="Suggestion">Make A Suggestion</option>
                    <option value="Complaint">Stage A Complaint</option>
                    <option value="Illegal Activity">
                      Report Illegal Activity
                    </option>
                  </Select>
                  <Select
                    value={duty}
                    onChange={(e) => setDuty(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select your duty
                    </option>
                    <option value="SGN">SGN</option>
                    <option value="SMN">SMN</option>
                    <option value="CMN">CMN</option>
                    <option value="GJN">GJN</option>
                    <option value="JJN">JJN</option>
                    <option value="IWN">IWN</option>
                    <option value="TJN">TJN</option>
                    <option value="GGN">GGN</option>
                    <option value="GYJN">GYJN</option>
                    <option value="BGYJN">BGYJN</option>
                    <option value="JPSN">JPSN</option>
                    <option value="HYJN">HYJN</option>
                    <option value="JMN">JMN</option>
                  </Select>
                  <Input
                    type="text"
                    placeholder="Contact Number"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    required
                  />
                  <TextArea
                    placeholder="5W and 1H"
                    value={comment}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>

                  <SubmitButton onClick={Home}>
                  <HomeIcon className="icon" />
                  <span className="text">{"Home"}</span>
                </SubmitButton>
                  <Text>
                    <p>
                      -	All information can only be accessed by the head instructors of each church and will not be shared elsewhere.
                    </p>
                    <p>
                      -	Please follow the order by inquiring first, and then if the issue remains unresolved, use this suggestion box to make your suggestions.
                    </p>
                    <p>
                      -	Reports on illegal activities should be brief and focus on information following the five W's and one H (Who, When, Where, What, How, Why).
                    </p>
                </Text>
                </Form>
              </FormContainer>
              <StyledModal
                closeTimeoutMS={300}
                className="mainHeroModal"
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                shouldCloseOnOverlayClick={true}
              >
                <CloseModalButton onClick={toggleModal}>
                  <CloseIcon tw="w-6 h-6" />
                </CloseModalButton>
                <MainContent>
                  <Heading>Submission Successful</Heading>
                  <br />
                  <br />
                  <p tw="text-center">Your comment has been submitted.</p>
                  <PrimaryButton onClick={toggleModal}>Close</PrimaryButton>
                </MainContent>
              </StyledModal>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
        
      {/* <MiniCenteredFooter tw="" /> */}
    </AnimationRevealPage>
  );
};