import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import tw from "twin.macro";
import styled from "styled-components";
import supabase from "helpers/SupabaseClient";
import * as SupabaseAPI from "helpers/SupabaseAPI";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./styles.css"
import { Provider, defaultTheme, DateRangePicker } from "@adobe/react-spectrum";
import { today } from '@internationalized/date';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SearchContainer = tw.div`flex justify-start`
const Input = tw.input`w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SearchFilterColumn = tw.span`w-full md:w-1/4 inline-block`
const ExportButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }nowtpday
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
    columns = [
        {
            name: 'Registration Number',
            search_value: 'scj_id',
            selector: row => row.scj_id,
            sortable: true,
        },
        {
            name: "Name",
            search_value: 'name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Email",
            search_value: 'email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: "Region of Registration",
            search_value: 'region',
            selector: row => row.region,
            sortable: true,
        },
        {
            name: "Country of Access",
            search_value: 'country_of_access',
            selector: row => row.country_of_access,
            sortable: true,
        },
        {
            name: "City of Access",
            search_value: 'city_of_access',
            selector: row => row.city_of_access,
            sortable: true,
        },
        {
            name: "DateTime",
            search_value: 'created_at',
            selector: row => new Date(row.created_at).toString(),
            sortable: true,
        }
    ],

    data = [
        {
            registration_number: "00400120-00089",
            country: "South Africa",
            datetime: "2024-05-25 16:05:00"
        },
        {
            registration_number: "00400120-00083",
            country: "Namibia",
            datetime: "2024-05-25 16:05:00"
        },
        {
            registration_number: "00390120-00189",
            country: "South Africa",
            datetime: "2024-05-25 16:05:00"
        },
        {
            registration_number: "00400327-00122",
            country: "Kenya",
            datetime: "2024-05-25 16:05:00"
        },
    ],
    heading = "Logins Log Data"
}) => {

    const [logs, setLogs] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [showingSearchResults, setShowingSearchResults] = useState(false);

    const [table_ord, setTableOrd] = useState("created_at");
    const [searchColumn, setSearchColumn] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [dateSelected, setDateSelected] = useState({
        start: today('Africa/Johannesburg'),
        end: today('Africa/Johannesburg')
    })
    const [downloadingLogs, setDownloadingLogs] = useState(false);

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const exportLogs = async () => {


        var offset = 0;
        var limitSize = 1000;
        var retrievedCount = 0;


        setDownloadingLogs(true)

        const { _data, _error, count } = await supabase
            .from('user_login_logs')
            .select("*", { count: "exact", head: true });

        console.log(count)

        const a = document.createElement('a');

        while (retrievedCount < count) {

            var data = await getTableRows(limitSize, offset)

            offset += 1000;
            retrievedCount += 1000;

            console.log(offset)
            console.log(retrievedCount)

            const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = `light_rain_air_user_login_logs-${new Date().toString()}.csv`;
            a.click();
        }

        setDownloadingLogs(false)
    }

    const exportByColumn = async () => {
        setDownloadingLogs(true)
        const a = document.createElement('a');
        var data = await SupabaseAPI.exportByColumn('user_login_logs', searchColumn, searchValue, table_ord)

        console.log(data)

        const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = `light_rain_air_user_login_logs-${searchColumn}-${searchValue}-${table_ord}-filtered.csv`;
        a.click();

        setDownloadingLogs(false)
    }

    const exportDateLogs = async () => {
        setDownloadingLogs(true)

        var offset = 0;
        var limitSize = 1000;
        var retrievedCount = 0;

        var count = await SupabaseAPI.countDateLogs('user_login_logs', 'created_at', dateSelected.from, dateSelected.to, table_ord)
        console.log("Date record count", count)

        const a = document.createElement('a');

        while (retrievedCount < count) {

            var data = await getTableRows(limitSize, offset)

            var data = await SupabaseAPI.exportDateLogs('user_login_logs', 'created_at', dateSelected.from, dateSelected.to, table_ord, limitSize, offset)

            offset += 1000;
            retrievedCount += 1000;

            console.log(offset)
            console.log(retrievedCount)

            const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = `light_rain_air_user_login_logs-${dateSelected.from.toString()}-${dateSelected.to.toString()}.csv`;
            a.click();
        }

        setDownloadingLogs(false)
    }

    const getTableRows = async (limit, offset) => {

        const { data, error } = await supabase
            .from('user_login_logs')
            .select('*')
            .order('created_at', { ascending: false })
            .range(offset, offset + limit)
            .csv()

        return data
    }

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(() => {
            searchByColumn(value);
        }, 200);  // 100 ms delay for debouncing

        setDebounceTimeout(newTimeout);
    };

    const searchByColumn = async (value) => {
        try {
            let data;
            if (!value.trim()) {
                // Fetch all entries if the search query is empty
                await fetchLogs()
                data = logs;
            } else {
                // Perform a full-text search if there is a query
                var count = await SupabaseAPI.countSearchByColumn('user_login_logs', searchColumn, value)
                data = await SupabaseAPI.searchByColumn('user_login_logs', searchColumn, value, page, perPage, table_ord);
                setSearchValue(value)
                setTotalRows(count)
            }
            setLogs(data);
            setShowingSearchResults(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLogs([]); // Handle error by clearing results
            setShowingSearchResults(false);
        }
    }


    const searchByDate = async (startDate, endDate) => {

        try {
            let data;
            data = await SupabaseAPI.searchByDate('user_login_logs', 'created_at', startDate, endDate, page, perPage, table_ord)

            setLogs(data);
            setShowingSearchResults(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLogs([]); // Handle error by clearing results
            setShowingSearchResults(false);
        }
    }

    const handleCheckChange = (event) => {
        const { value } = event.target;
        console.log(value)
        setSearchColumn(value);
    }

    const handleDateChange = async (date) => {

        var startDate = `${date.start.year}-${date.start.month}-${date.start.day}`
        var endDate = `${date.end.year}-${date.end.month}-${date.end.day}`

        console.log(date)

        setDateSelected(date)

        searchByDate(startDate, endDate)

        var count = await SupabaseAPI.countDateLogs('user_login_logs', 'created_at', startDate, endDate)
        setTotalRows(count)
        console.log("Dates", date, "Date record count", count)

    }


    const handlePageChange = page => {
        setPage(page)
        fetchLogs();
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setPerPage(newPerPage);
        await fetchLogs();
        setLoading(false);
    };

    async function fetchLogs() {
        setLoading(true);
        var count = 0;
        var data = []
        if (searchColumn !== "" && searchColumn !== "created_at|DateTime") {
            count = await SupabaseAPI.countSearchByColumn('user_login_logs', searchColumn, searchQuery)
            data = await SupabaseAPI.searchByColumn('user_login_logs', searchColumn, searchQuery, page, perPage, table_ord);
        }
        else if (searchColumn === "created_at|DateTime") {

            var startDate = `${dateSelected.start.year}-${dateSelected.start.month}-${dateSelected.start.day}`
            var endDate = `${dateSelected.end.year}-${dateSelected.end.month}-${dateSelected.end.day}`

            count = await SupabaseAPI.countDateLogs('user_login_logs', 'created_at', startDate, endDate)
            data = await SupabaseAPI.searchByDate('user_login_logs', 'created_at', startDate, endDate, page, perPage, table_ord)
        }
        else {
            count = await SupabaseAPI.getTableRowCount('user_login_logs');
            data = await SupabaseAPI.getPaginatedData('user_login_logs', page, perPage, 'created_at');
        }


        setTotalRows(count);
        setLogs(data);
        setLoading(false);
    };

    function export_file() {
        if (searchColumn === "") {
            exportLogs()
        }
        else if (searchColumn === 'created_at|DateTime') {
            exportDateLogs()
        }
        else {
            exportByColumn()
        }

    }

    function get_log_download_label() {
        if (searchColumn === "") {
            return "All"
        }
        else {
            return "Filtered"
        }
    }
    useEffect(() => {

        fetchLogs();
    }, [])

    return (
        <Container>
            <Content>
                <HeadingRow>
                    <Heading>
                        <HighlightedText>{heading}</HighlightedText>
                    </Heading>

                </HeadingRow>
                {searchColumn !== 'created_at|DateTime' && <SearchContainer style={{ margin: '20px' }}>
                    <Input
                        type="text"
                        style={{
                            fontSize: '18px',  // Larger font size
                            border: '2px solid pink',  // Pink border
                            padding: '8px',
                            width: '100%'
                        }}
                        placeholder={`Search by ${searchColumn.split("|")[1]}`}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </SearchContainer>}
                <p style={{ textAlign: "center" }}>Search by column:</p>
                <br />
                <div style={{flex: 'row', width: '80%', margin: 'auto'}}>
                    {columns.map((col, i) => {

                        return <span key={i}>
                            <SearchFilterColumn>
                                <Input
                                    type="checkbox"
                                    style={{
                                        fontSize: '18px',  // Larger font size
                                        border: '2px solid pink',  // Pink border
                                        padding: '8px',
                                        width: '10%', 
                                        marginLeft: '20px'
                                    }}
                                    value={`${col.search_value.toLowerCase()}|${col.name}`}
                                    id={col.search_value.toLowerCase()}
                                    checked={searchColumn === `${col.search_value.toLowerCase()}|${col.name}`}
                                    onChange={handleCheckChange}
                                />
                                <label htmlFor={col.search_value.toLowerCase()}>{col.name}</label>
                            </SearchFilterColumn>
                        </span>
                    })}
                </div>

                <br />
                <a href="#" style={{margin: 'auto', paddingTop: '20px', display: 'block', textAlign: 'center'}} onClick={async () => {setSearchColumn(""); setSearchQuery(""); await fetchLogs();}}><b>Clear filters</b></a>



                <br />

                {searchColumn === 'created_at|DateTime' &&

                    <Provider theme={defaultTheme} colorScheme="light" >
                        <div style={{textAlign: 'center'}}>
                        <DateRangePicker
                            label="Date range (controlled)"
                            value={dateSelected}
                            onChange={handleDateChange} isQuiet />
                        </div>


                    </Provider>

                }
                <ExportButton onClick={() => export_file()} disabled={downloadingLogs === true} >
                    {downloadingLogs === true ? 'Busy downloading logs...' : `Export ${get_log_download_label()} Logs`}
                </ExportButton>

                <br />
                <br />
                {logs !== [] && <DataTable
                    // title="Logins Log Data"
                    columns={columns}
                    data={logs}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    selectableRows
                    pagination
                    paginationServer
                />}
                {
                    logs === [] && <p>Records loading</p>
                }
            </Content>
        </Container>
    )
}